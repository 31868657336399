import "./App.css";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Featured from "./components/Featured";
import MyWork from "./components/MyWork";
import Contact from "./components/Contact";
import ReactGA from "react-ga4";

ReactGA.initialize("G-BPYYFWJXBD");
function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <Featured />
      <Contact />
    </div>
  );
}

export default App;
