import React from "react";
import profile from "../Images/profile.webp";
import "./Hero.css";
import LazyLoad from "react-lazy-load";

const Hero = () => {
  return (
    <div className="hero">
      <div className="left">
        <p className="first">Google Flutter | NodeJs</p>
        <h2>
          Android & iOS <br /> Developer
        </h2>
        <p className="second">I am freelance app developer.</p>
      </div>
      <div className="right">
        <LazyLoad>
          <img src={profile} alt="Ram Gupta" />
        </LazyLoad>
      </div>
    </div>
  );
};

export default Hero;
